import { Injectable } from '@angular/core';
import { RoleType } from '@tfagency/core/data-access';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private readonly localStorageKey = 'id_token_claims_obj';

  getUid(): string {
    return JSON.parse(localStorage.getItem(this.localStorageKey) as string)[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
    ];
  }

  getRole(): RoleType {
    return JSON.parse(localStorage.getItem(this.localStorageKey) as string)
      ?.role;
  }

  getFullName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getFirstName(): string {
    return JSON.parse(localStorage.getItem(this.localStorageKey) as string)[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
    ];
  }

  getLastName(): string {
    return JSON.parse(localStorage.getItem(this.localStorageKey) as string)[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
    ];
  }
}
